import axios from 'axios';
import Cookies from 'js-cookie';
import router from './router';

const apiClient = axios.create({
    baseURL: "/api/v1",
    timeout: 60000
});

// request interceptor
apiClient.interceptors.request.use(
  (config) => {
    const token = Cookies.get('cdd_token');

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      router.push({ name: 'Login' });
      return Promise.reject(new Error('No authentication token found'));
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default apiClient;
