<template>
    <div class="loader-container" :style="{ display: loading ? 'flex' : 'none' }">
      <div class="loader"></div>
      <p class="mt-3">Processing...</p>
    </div>

    <h3 class="text-center">Оберіть варіант класифікації</h3>

    <div class="box mt-5">
      <div class="row">
        <!-- Predict One or Many -->
        <div class="col-sm-6 mb-2">
          <div class="card h-100">
            <form @submit.prevent="classifyOnScreen" class="card-body d-flex flex-column justify-content-between">
              <div>
                <h5 class="card-title mb-3">Один чи декілька</h5>
                <textarea v-model="descriptions" class="form-control" rows="12" placeholder="Введіть опис СКЮ сюди. Можна використовувати багато рядків, щоб опрацювати декілька SKU одночасно"></textarea>
              </div>
              <button class="btn btn-primary w-100 mt-3" type="submit">Класифікувати</button>
            </form>
          </div>
        </div>

        <!-- Process File -->
        <div class="col-sm-6 mb-2">
          <div class="card h-100">
            <form @submit.prevent="classifyFile" class="card-body d-flex flex-column justify-content-between">
              <div>
                <h5 class="card-title mb-3">Класифікація в файлі</h5>
                <input type="file" @change="handleFileUpload" accept=".csv, .xlsx" class="form-control" />
                <label class="mt-2">
                  <p>Можна завантажувати дані тільки в CSV чи Excel форматах. Всі описи для класифікації мають бути в першій колонці (A).</p>
                  <p>
                    Шаблон файлу для завантаження доступний за <a href="/examples/classificator import template.csv">посиланням</a>.
                  </p>
                </label>
              </div>
              <div>
                <p v-if="!resultFileUrl"> {{ successMessage }}</p>
                <a v-if="resultFileUrl" :href="resultFileUrl" target="_blank" rel="noopener noreferrer">Завантажити файл з результатами класифікації</a>
              </div>
              <button class="btn btn-primary w-100 mt-3" type="submit">Класифікувати</button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Error message -->
    <div v-if="errorMessage" class="alert alert-danger mt-4 text-center">{{ errorMessage }}</div>

    <!-- Predictions table -->
    <div v-if="predictions.length" class="mt-4">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Запит</th>
            <th>Категорія</th>
            <th>Підкатегорія</th>
            <th>Тип</th>
            <th>ТМ</th>
            <th>Фасований</th>
            <th>Вага</th>
            <th>Група</th>
            <th>Пакування</th>
            <th>Температурні умови</th>
            <th>Аналітична група</th>
          </tr>
        </thead>
        <tbody>
            <tr v-for="prediction in predictions" :key="prediction.id">
                <td>{{ prediction.searched_description }}</td>
                <td>{{ prediction.category }}</td>
                <td>{{ prediction.subcategory }}</td>
                <td>{{ prediction.type }}</td>
                <td>{{ prediction.brand }}</td>
                <td>{{ prediction.packaging == 1 ? 'так' : 'ні' }}</td>
                <td>{{ (prediction.weight_value > 0 ? prediction.weight_value : '')  + prediction.weight_unit }}</td>
                <td>{{ prediction.group }}</td>
                <td>{{ prediction.package_type }}</td>
                <td>{{ prediction.temperature_condition }}</td>
                <td>{{ prediction.analytic_group }}</td>
            </tr>
        </tbody>
      </table>
    </div>
</template>

<script>
import apiClient from '../axiosSetup.js';

export default {
  data() {
    return {
      descriptions: '',
      predictions: [],
      errorMessage: '',
      successMessage: '',
      file: null,
      loading: false,
      taskId: null,
      taskStatus: null,
      taskResult: null,
      fileTaskId: null,
      fileTaskStatus: null,
      fileTaskResult: null,
      resultFileUrl: null
    };
  },
  methods: {
    async classifyOnScreen() {
        this.resetFields();
        this.taskId = null;
        this.taskStatus = null;
        this.taskResult = null;

        if (this.descriptions === '') {
            this.errorMessage = 'Data can not be empty';
            return;
        }

        let descriptionsJson = this.convertTextAreaToJson(this.descriptions);

        this.loading = true;

        try {
          const response = await apiClient.post('/classification/task', descriptionsJson, {
            headers: {
              'Content-Type': 'application/json',
            },
          });

          this.taskId = response.data.task_id;
          this.taskStatus = 'PENDING';

          await this.pollOnScreenTaskStatus();
        } catch (error) {
          console.error("Error starting task: ", error);
        }
    },
    async pollOnScreenTaskStatus() {
      const interval = setInterval(async () => {
        try {
          const response = await apiClient.get(`/classification/task/${this.taskId}`);
          this.taskStatus = response.data.status;

          if (response.data.status === 'SUCCESS') {
            this.loading = false;

            this.predictions = response.data.result;
            clearInterval(interval);
          }
        } catch (error) {
          this.loading = false;

          console.error("Error fetching task status: ", error);
          clearInterval(interval)
        }
      }, 2000);
    },
    convertTextAreaToJson(textAreaContent) {
      const lines = textAreaContent.split('\n');
      let jsonArray = [];

      lines.forEach(function(line) {
          line = line.trim();

          if  (line.length > 0 && (typeof line === 'string' || line instanceof String)) {
              let jsonObject = {};
              jsonObject['description'] = line;
              jsonArray.push(jsonObject);
          }
      });
      return JSON.stringify(jsonArray);
    },
    async classifyFile() {
      this.resetFields();
      this.fileTaskId = null;
      this.fileTaskStatus = null;
      this.fileTaskResult = null;
      this.resultFileUrl = null;

      if (this.file == null || this.file.name === '') {
        this.errorMessage = 'No file was selected';
        return;
      }

      const fileSizeLimitMB = 6;
      if (this.file.size > fileSizeLimitMB * 1024 * 1024) {
          this.errorMessage = 'The file is too big. File size is limited by ' + fileSizeLimitMB + 'MB';
          return;
      }

      this.loading = true;

      // Create FormData to send the file
      const formData = new FormData();
      formData.append('file', this.file);

      try {
        const response = await apiClient.post('/classification/file/task', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        this.successMessage = 'Йде класифікація. Очікуйте на посилання на файл з результатами';

        this.fileTaskId = response.data.task_id;
        this.fileTaskStatus = 'PENDING';

        await this.pollFileClassificationTaskStatus();
      } catch (error) {
            // Handle error response
            if (error.response) {
              switch (error.response.status) {
                case 401:
                  this.errorMessage = 'Please login first';
                  break;
                case 400:
                  this.errorMessage = 'Check if all values are valid in the uploaded file';
                  break;
                case 409:
                  this.errorMessage = 'Model is not trained yet. Try again or contact support team, please';
                  break;
                case 415:
                  this.errorMessage = 'Wrong CSV file format - only CSV or Excel are supported';
                  break;
                case 422:
                  this.errorMessage = 'Wrong CSV file encoding - please use UTF-8 or ASCII during saving the file.';
                  break;
                default:
                  this.errorMessage = error.response.statusText;
                  break;
              }
            } else {
              this.errorMessage = 'File upload failed';
            }
      } finally {
        this.loading = false;
      }
    },
    async pollFileClassificationTaskStatus() {
      const interval = setInterval(async () => {
        try {
          const response = await apiClient.get(`/classification/file/task/${this.fileTaskId}`);
          this.fileTaskStatus = response.data.status;

          if (response.data.status === 'completed') {
            this.loading = false;

            this.resultFileUrl = response.data.file_url;
            clearInterval(interval);
          }
        } catch (error) {
          this.loading = false;

          console.error("Error fetching task status: ", error);
          clearInterval(interval)
        }
      }, 2000);
    },
    handleFileUpload(event) {
      this.file = event.target.files[0];
    },
    resetFields() {
      this.errorMessage = '';
      this.predictions = [];
    }
  },
};
</script>
